import React, { useEffect } from "react"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import { ContentWrapper } from "../containers/alpacked.style"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

const CaseStudy = ({ location }) => {
  useEffect(() => {
    navigate(
      `/begin-your-reliable-way-to-compliance?utm_source=email&utm_campaign=SF93f7iAY/1gqdLklsUs0/9/DBp48PhKqy8shkxHPdKgdsL9fTfGG6EmH5RhnClFZcwamtusmX9/8+v1zyoe7g==`
    )
  }, [])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Begin your reliable way to compliance"
        description="Begin your reliable way to compliance"
      />
      <Layout>
        <ContentWrapper>
          <iframe
            src="/begin-your-reliable-way-to-compliance.pdf"
            width="100%"
            height="1000"
            title="Begin your reliable way to compliance"
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(CaseStudy, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
